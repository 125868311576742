import { BBVersions } from "../reducers/models";

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function formatUNIXTimestamp(timestamp, hyperWalletStyle) {
  if (timestamp) {
    const dateObject = new Date(timestamp);
    let month = dateObject.toLocaleString("en-US", { month: "short" });
    let day = dateObject.toLocaleString("en-US", { day: "numeric" });
    let year = dateObject.toLocaleString("en-US", { year: "numeric" });
    if (hyperWalletStyle) {
      month = dateObject.toLocaleString("en-US", { month: "2-digit" });
      day = dateObject.toLocaleString("en-US", { day: "2-digit" });
      year = dateObject.toLocaleString("en-US", { year: "numeric" });

      return `${year}-${month}-${day}`;
    }
    return `${month} ${day}, ${year}`;
  }
  return "";
}

export function getBBVersion(productType) {
  switch (productType) {
    case "BB2":
    case "Buildbox Classic":
      return BBVersions.BUILDBOX2;
    case "BB3":
    case "Buildbox 3":
      return BBVersions.BUILDBOX3;
    case "BB4":
    case "Buildbox 4":
      return BBVersions.BUILDBOX4;
    case "BBWorld":
      return BBVersions.BUILDBOXWORLD;
    case "Soundbox":
      return BBVersions.SOUNDBOX;
    case "generic":
      return BBVersions.GENERIC;
    default:
      return BBVersions.GENERIC;
  }
}

export function openNewWindow(url, writeToWindow) {
  var y = window.outerHeight / 2 + window.screenY - 500 / 2;
  var x = window.outerWidth / 2 + window.screenX - 500 / 2;
  let newwindow = window.open(
    url,
    "",
    `height=1000,width=1000, top=${y},left=${x}`
  );
  if (window.focus) {
    if (writeToWindow) {
      newwindow.document.write(
        `
        <iframe src="https://docs.google.com/gview?url=${url}&embedded=true" style="width:1000px; height:1000px;" frameborder="0"></iframe>
  `
      );
    }
    newwindow.focus();
  }
  return false;
}
