import Log from "../utils/Log";
import { push, replace } from "connected-react-router";
import Auth from "@aws-amplify/auth";
import { DefaultThunkAction } from "./shared";
import { AnyAction } from "redux";
import actions from "../constants/actions.json";
import routes from "../constants/routes.json";
import {
  createUser,
  fetchTokensforCode,
  fetchUserScreenName,
  updateUserScreenName,
} from "../api/auth";
import { sendReferralTrackingId } from "../api/referralprogram";
import { addMailchimpAudienceTags } from "../api/mailchimp";
import { showError } from "./error";
import { LoginClientType, LoginState, WorkState } from "../reducers/models";
import {
  setFromSSORedirect,
  resetCurrentState,
  setLoginWorkState,
  setSignUpWorkState,
  setProductType,
  setLoggingInfromClient,
} from "./current";
import {
  getCognitoUser,
  checkTokenExpiration,
  getRefreshedCognitoTokens,
  refreshWebAccessToken,
  getUserAttributes,
} from "../utils/CognitoUtils";
import { setGAClientId, setAnalyticsRegistrationEvent } from "./analytics";
import { getGAClientId } from "../utils/analyticsUtils";
// import { findCookieValue } from "../utils/referralUtils";
import { clearAccount } from "./account";
import { getDeviceType } from "../utils/browserUtils";
import { getRandomColor, getBBVersion } from "../utils/helperUtils";
import { validateEmail } from "../utils/validationForm";
export function login(props: any): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(setLoginWorkState(LoginState.Loading));
    try {
      const cognitoUserInfo:
        | bb.model.ICognitoUser
        | any = await getCognitoUser();
      const { accessToken, refreshToken, referralTrackingId } = cognitoUserInfo;
      // console.log(cognitoUserInfo);
      //if we detect a referral cookie on login include it for user creation
      const xsollaTrackingId = referralTrackingId;

      //console.log("login referral link", referralTrackingId);
      let ReferralInfo = null;
      if (xsollaTrackingId) {
        ReferralInfo = {
          Network: "Xsolla",
          Info: xsollaTrackingId,
        };
      }

      const response = await createUser(accessToken, ReferralInfo);

      const { user, userCreated } = response.payload;

      const userAttributes = await getUserAttributes(accessToken);

      const { email, name, username, locale } = userAttributes;

      const userScreenName = await fetchUserScreenName(accessToken);

      dispatch(setUserScreenName(userScreenName));
      const userObject = {
        username: username,
        name: name,
        uuid: user.Id,
        email: email,
        // screenName: "",
        screenName: userScreenName || "",
        country: locale ? userAttributes.locale : "United States of America",
        isNewUser: userCreated,
        referralInfo: user.ReferralInfo,
      };

      const tokenObject = {
        refreshToken,
        accessToken,
      };
      const productType = sessionStorage.getItem("websocket-product")
        ? sessionStorage.getItem("websocket-product")
        : sessionStorage.getItem("productType");

      sessionStorage.setItem("websocket-accessToken", accessToken);
      sessionStorage.setItem("websocket-refreshToken", refreshToken);
      sessionStorage.setItem("websocket-email", email);
      sessionStorage.setItem("websocket-username", username);

      sessionStorage.setItem("profileColor1", getRandomColor());
      sessionStorage.setItem("profileColor2", getRandomColor());

      dispatch(setUser(userObject));

      dispatch(setAuthTokens(tokenObject));

      const clientId = await getGAClientId();

      await dispatch(setGAClientId(clientId));

      //if we detect a cookie on login fire referral attribution
      // note this has to come after create user so we have the user id
      // maybe we could move it to platform side in the future.
      if (xsollaTrackingId) {
        await sendReferralTrackingId(xsollaTrackingId, user.Id);
      }

      if (userCreated) {
        await dispatch(handleNewUserRegistration(userObject, clientId));
        if (!email) return;
        await dispatch(setMailchimpMarketingTags(email, productType));
      } else {
        Log.info(email, "not a new user");
      }
      dispatch(setLoginWorkState(LoginState.None));
    } catch (e:any) {
      Log.error(e, "Error dispatching: login");

      //show a global toast error for server errors on our end after Cognito signin
      dispatch(showError(e.message));

      if (e.response && e.response.status === 401) {
        dispatch(setLoginWorkState(LoginState.Unauthorized));
      } else {
        dispatch(setLoginWorkState(LoginState.ServerError));
      }
    }
  };
}

export function logout(url?: string): DefaultThunkAction<Promise<void>> {
  const previousRoute = sessionStorage.getItem("previousRoute");

  return async (dispatch) => {
    try {
      await Auth.signOut();
      dispatch(clearUser());
      dispatch(resetCurrentState());
      dispatch(clearAccount());

      localStorage.clear();
      sessionStorage.clear();

      sessionStorage.setItem("previousRoute", previousRoute || "");

      if (url && url.length) {
        window.location.href = url;
      }
      //else if (previousRoute) {
      //   dispatch(push(previousRoute));
      // } else {
      //   dispatch(push(routes.LOGIN));
      // }
    } catch (e:any) {
      Log.error(e, "Error dispatching: logout");
      let message = e.message
      dispatch(showError(message));
    }
  };
}

export function ssoTokenLogin(
  token: string,
  goToPlansPage: boolean,
  planView?: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(setLoginWorkState(LoginState.Loading));
    try {
      const response = await fetchTokensforCode(token);

      Log.info(response, "Response from fetchTokensforCode");
      const {
        userId,
        email,
        accessToken,
        clientId,
        refreshToken,
        clientSecret,
        referralInfo,
      } = response;
      const userAttributes = await getUserAttributes(accessToken);
      Log.info(userAttributes, "Response from userAttributes");

      const userScreenName = await fetchUserScreenName(accessToken);

      const userObject = {
        username: userAttributes.username,
        name: userAttributes.name || email,
        uuid: userId,
        screenName: userScreenName || "",
        email: userAttributes.email || email,
        isNewUser: false,
        referralInfo: referralInfo,
      };

      const tokenObject = {
        refreshToken,
        accessToken,
      };

      Log.info(response, "fetchTokensforCode response");

      const gaClientId = await getGAClientId();

      dispatch(setGAClientId(gaClientId));

      //set the user object here
      dispatch(setUserScreenName(userScreenName));
      dispatch(setUser(userObject));

      dispatch(setCognitoClientInfo(clientId, clientSecret));
      dispatch(setAuthTokens(tokenObject));

      dispatch(setFromSSORedirect(true));
      dispatch(setLoggingInfromClient(LoginClientType.Client));
      dispatch(setLoginWorkState(LoginState.None));

      if (goToPlansPage) {
        if (planView && planView.length) {
          dispatch(setProductType(planView));
          dispatch(replace(`${routes.PURCHASE_PLANS}/${planView}`));
        } else {
          dispatch(push(routes.PURCHASE_PLANS));
        }
      }
    } catch (e:any) {
      Log.error(e, "Error dispatching: ssoTokenLogin");

      if (e.response && e.response.status === 401) {
        dispatch(setLoginWorkState(LoginState.Unauthorized));
      } else {
        dispatch(setLoginWorkState(LoginState.ServerError));
      }
      console.log("redirecting in sso token login!!");

      //redirect user to web login
      dispatch(push(routes.LOGIN));
    }
  };
}

export function handleNewUserRegistration(
  user: bb.model.IUser,
  clientId: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    try {
      const { email, uuid } = user;
      let productName = sessionStorage.getItem("productType")?.toLowerCase()
      let productType
      if(productName?.includes("bb2")) {
        productType = "buildbox2"
      }
      else if(productName?.includes("bb3")) {
        productType = "buildbox3"
      }
      else if(productName?.includes("bb4")) {
        productType = "buildbox4"
      }      
      else if(productName?.includes("soundbox")) {
        productType = "soundbox"
      }
      else {
        //console.error("unrecognized product: ", productName)
        //throw new Error("unrecognized product during user registration " + productName)

        // TODO: should this be 'generic' or something?
        //  throwing an error doesn't seem great
        console.log("no product selected on signup, defaulting to buildbox4")
        productType = "buildbox4"
      }
      const deviceType = getDeviceType();

      Log.trace(deviceType, "User is signing up on:");
      await dispatch(
        setAnalyticsRegistrationEvent(
          email,
          uuid,
          clientId,
          productType,
          deviceType
        )
      );

      setSignUpWorkState(WorkState.None);
    } catch (error:any) {
      Log.error(error, "Error dispatching: handleNewUserRegistration");
      setSignUpWorkState(WorkState.Error);
      throw error;
    }
  };
}

//used to set mailchimp tags by product type for signed up user
export function setMailchimpMarketingTags(
  email: string,
  productType: string | null
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(setSignUpWorkState(WorkState.Loading));
    try {
      const product = getBBVersion(productType);
      const response = await addMailchimpAudienceTags(email, product);
      Log.info(
        response,
        `new user - adding Mailchimp Tags for product type: ${product}`
      );
      dispatch(setSignUpWorkState(WorkState.None));
    } catch (error:any) {
      //Log.error(error, "error caught in setAnalyticsRegistrationEvent");
      //dispatch(setSignUpWorkState(WorkState.Error));
      //throw error;

      // mailchimp gives too many unimportant errors, let's catch this and move on
      console.log("error adding mailchimp tags", error);
      dispatch(setSignUpWorkState(WorkState.None));
    }
  };
}

//validates and refreshes cognito auth tokens

export function validateToken(): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(setLoginWorkState(LoginState.Loading));

    try {
      const { auth } = getState();
      if(!auth || !auth.authTokens) {
        dispatch(setLoginWorkState(LoginState.Unauthorized));
        throw new Error("Please log in")
      }

      const { accessToken, refreshToken } = auth.authTokens;
      if(!accessToken || !refreshToken) {
        dispatch(setLoginWorkState(LoginState.Unauthorized));
        throw new Error("Please log in")
      }

      let tokenIsExpired = await checkTokenExpiration(accessToken);
      Log.trace(tokenIsExpired, "Validate token: is token expired?");

      if (tokenIsExpired || !accessToken) {
        const refreshedToken = await dispatch(refreshTokens(refreshToken));
        return refreshedToken;
      } else {
        dispatch(setLoginWorkState(LoginState.None));
        Log.info("", "access token is looking ok");
        return accessToken;
      }
    } catch (error:any) {
      Log.error(error, "Error dispatching: validateToken");
      dispatch(setLoginWorkState(LoginState.Unauthorized));

      throw error;
    }
  };
}

export function refreshTokens(
  refreshToken: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(setLoginWorkState(LoginState.Loading));

    try {
      const { auth } = getState();
      const { cognitoClientId, clientSecret } = auth;
      let newToken = null;

      // refreshing with client tokens
      if (cognitoClientId && clientSecret) {
        newToken = await getRefreshedCognitoTokens(
          refreshToken,
          cognitoClientId,
          clientSecret
        );
      } else {
        newToken = await refreshWebAccessToken();
      }

      const tokenObject = {
        accessToken: newToken,
        refreshToken: refreshToken,
      };
      dispatch(setAuthTokens(tokenObject));
      dispatch(setLoginWorkState(LoginState.None));
      return newToken;
    } catch (error:any) {
      Log.error(error, "Error dispatching: refreshTokens");
      dispatch(setLoginWorkState(LoginState.Unauthorized));

      let message = error.message
      if (message) {
        throw new Error(message);
      } 
      else {
        let newError = error;
        if (error === "not authenticated") {
          newError = new Error(
            `Your session has expired. Please sign in and try again`
          );
          // manual forced logout to prevent potential infinite loop
          await Auth.signOut();
          dispatch(clearUser());
          dispatch(resetCurrentState());
          dispatch(clearAccount());
    
          localStorage.clear();
          sessionStorage.clear();    
        }
        dispatch(push(routes.LOGIN));
        throw newError;
      }
    }
  };
}

export function handleUpdateUserScreenName(
  screenName: string
): DefaultThunkAction<Promise<void>> {
  return async (dispatch) => {
    const accessToken = await dispatch<Promise<string>>(validateToken());
    try {
      const newScreenName = await updateUserScreenName(accessToken, screenName);
      console.log(newScreenName);
      dispatch(setUserScreenName(newScreenName));

      return newScreenName;
    } catch (error:any) {
      console.log("error: ", error);
      // let err
      // if ( error.metadata.errorcode === 'ERROR_SCREEN_NAME_IN_USE'){
      //   err = 'This Creator Name is already taken. Please choose a different one.'
      // }
      // if (error.metadata.errorcode  === 'ERROR_SCREEN_NAME_INAPPROPRIATE' ){
      //   err = error.metadata.message
      // }
      // if ( error.metadata.errorcode  === 'ERROR_INVALID_SCREEN_NAME' ){
      //   err = 'Creator Name must: be 3-25 characters long, only contain letters, numbers, dashes, periods, or underscores'
      // }

      let errorcode = error.metadata.errorcode
      throw errorcode;
    }
  };
}
export function setCognitoClientInfo(
  cognitoClientId: string,
  clientSecret: string
) {
  return {
    type: actions.auth.SET_COGNITO_CLIENT_INFO,
    cognitoClientId,
    clientSecret,
  };
}

export function setUserScreenName(screenName: string): AnyAction {
  return {
    type: actions.auth.SET_SCREEN_NAME,
    screenName,
  };
}

export function setAuthTokens(tokens: bb.model.ITokens): AnyAction {
  return {
    type: actions.auth.SET_AUTH_TOKENS,
    tokens,
  };
}

export function setUser(user: bb.model.IUser): AnyAction {
  return {
    type: actions.auth.SET_USER,
    user,
  };
}

export function clearUser(): AnyAction {
  return {
    type: actions.auth.CLEAR_USER,
  };
}

export function setAccountlessPurchaseEmail(email: string | undefined): AnyAction {
  let actionBody: { 
    type: string,
    email: string | undefined
  } = {
    type: actions.auth.SET_ACCOUNTLESS_PURCHASE_EMAIL,
    email: undefined
  }
  if(!email) return actionBody
  
  if(validateEmail(email)){
    console.debug("email is valid")
    actionBody.email = email
  }
  return actionBody
}